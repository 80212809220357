import React, { useState } from "react"
import { ProcessTasksType } from "@prisma/client"
import useNotifications from "@hooks/notifications/useNotifications"
import { System } from "@design-system/index"
import { useTable } from "@design-system/Table/hooks/useTable"
import { IBulkAction } from "@design-system/Table/components/BulkActions/List"
import { CloudArrowUpIcon } from "@heroicons/react/24/outline"
import { find, get, isEmpty, isNil } from "lodash"
import { useProcessPendingAndLastFinishedContext } from "@components/Processes/contexts/ProcessPendingAndLastFinishedContext/hooks/useProcessPendingAndLastFinishedContext"
import { useGenericModal } from "@design-system/Context/GenericModal/hooks/useGenericModal"
import { RequestCacheClearFormInputs } from "@components/Processes/components/Form/types"
import { JSONWordPressCoreApiData } from "@/WordPressCore/types/api-types"
import { WordPressCoreComponents } from "@components/WordPressCore/components"
import { WordPressCoreAction } from "@components/WordPressCore/actions"

interface Props {
  items?: JSONWordPressCoreApiData[]
}

export function BulkActionAllWordPressCore({ items }: Props) {
  const { state, clearCheckedRows, addRowProcessingKeys } = useTable()

  const { openModal, closeModal } = useGenericModal()
  const [loading, setLoading] = useState(false)
  const { checkedRows } = state
  const { handleNotifySuccess, handleNotifyError } = useNotifications()
  const { mutate } = useProcessPendingAndLastFinishedContext()

  if (checkedRows.size === 0) {
    return null
  }

  const handleConfirmBulkUpdateAction = async ({
    needCacheClear,
  }: RequestCacheClearFormInputs) => {
    try {
      setLoading(true)
      const getData = () => {
        return Array.from(checkedRows)
          .map((keyItem) => {
            const item = items.find((wpcore) => {
              return wpcore.id === keyItem
            })

            if (isNil(item)) {
              return
            }

            if (!item.needWordPressCoreUpdate()) {
              return
            }

            return {
              type: ProcessTasksType.UPDATE_CORE,
              entities: {
                id: get(item, "id"),
                version: item.latest.version,
              },
              projectId: item.projectId,
            }
          })
          .filter((item) => !isNil(item))
      }

      const data = getData()

      const projectsClearCache: Array<{ projectId: number }> = needCacheClear
        ? data.reduce((acc, item) => {
            if (find(acc, { projectId: item.projectId })) {
              return acc
            }

            acc.push({
              projectId: item.projectId,
            })
            return acc
          }, [])
        : []

      const { rowProcessings, code } = await WordPressCoreAction.bulkTask({
        data: data,
        projectsClearCache: needCacheClear ? projectsClearCache : null,
      })

      mutate()

      if (code === "success") {
        addRowProcessingKeys({
          keys: rowProcessings,
        })
        clearCheckedRows()
        handleNotifySuccess({
          title: "We have started the process",
          message: <>We will inform you when it’s done.</>,
        })
      } else {
        handleNotifyError({
          title: "Oups",
          message: <>We were unable to start the process task.</>,
        })
      }
    } catch (error) {
      handleNotifyError({
        title: "Oups",
        message: <>We were unable to start the process task.</>,
      })
    }
    closeModal()
    setLoading(false)
  }

  const ACTIONS = [
    {
      key: ProcessTasksType.UPDATE_CORE,
      label: (
        <>
          <CloudArrowUpIcon className="h-4 w-4" />
          Update
        </>
      ),
      isDisabled() {
        const data = Array.from(checkedRows)
          .map((keyItem) => {
            const item = items.find((wpcore) => {
              return wpcore.id === keyItem
            })

            if (isNil(item)) {
              return
            }

            if (!item.needWordPressCoreUpdate()) {
              return
            }
            return item
          })
          .filter((item) => !isNil(item))

        return isEmpty(data)
      },
      onClick: () => {
        openModal({
          component: (
            <WordPressCoreComponents.Modal.BulkUpdateWordPressCore
              loading={loading}
              onConfirm={handleConfirmBulkUpdateAction}
            />
          ),
        })
      },
    },
  ] as IBulkAction[]

  return (
    <>
      <System.Table.BulkActions.List
        actions={ACTIONS}
        isShowing={checkedRows.size > 0}
      />
    </>
  )
}
