import {
  JSONPHPExtension,
  JSONWordPressCoreApiData,
  JSONWordPressLatestData,
} from "@/WordPressCore/types/api-types"
import { createSchema } from "morphism"
import WordPressVersionInfo from "./WordPressVersionInfo"

export const SchemaJSONWordPressCoreApiDataToWordPressCoreModel = createSchema<
  Omit<
    WordPressCoreModel,
    | "needWordPressCoreUpdate"
    | "getSqlVersionRecommandedByWordPress"
    | "getPhpVersionRecommandedByWordPress"
    | "getLatestWordPressVersion"
  >,
  JSONWordPressCoreApiData
>({
  id: "id",
  wordpress_version: "wordpress_version",
  is_up_to_date: "is_up_to_date",
  php_current_version: "php_current_version",
  php_recommended_version: "php_recommended_version",
  php_is_secure: "php_is_secure",
  php_is_supported: "php_is_supported",
  php_extensions: "php_extensions",
  latest: "latest",
  projectId: "projectId",
  Project: "Project",
  wpVersionInformation: (data) => {
    return new WordPressVersionInfo(data)
  },
})

class WordPressCoreModel implements JSONWordPressCoreApiData {
  Project: { base_url: string; name: string; hosting: string }
  id: string
  wordpress_version: string
  is_up_to_date: boolean
  php_current_version: string
  php_recommended_version: string
  php_is_secure: boolean
  php_is_supported: boolean
  php_extensions: JSONPHPExtension[]
  latest?: JSONWordPressLatestData
  projectId: number
  wpVersionInformation: WordPressVersionInfo

  needWordPressCoreUpdate(): boolean {
    return this.wpVersionInformation.needWordPressCoreUpdate()
  }
  getSqlVersionRecommandedByWordPress(): string {
    return this.wpVersionInformation.getSqlVersionRecommandedByWordPress()
  }
  getPhpVersionRecommandedByWordPress(): string {
    return this.wpVersionInformation.getPhpVersionRecommandedByWordPress()
  }
  getLatestWordPressVersion(): string {
    return this.wpVersionInformation.getLatestWordPressVersion()
  }
}

export default WordPressCoreModel
