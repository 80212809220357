import { System } from "@design-system/index"
import { TableRowProcess } from "@design-system/Table/types"
import { WordPressCoreRow } from "./Row"
import { useEffect } from "react"
import { isNil } from "lodash"
import usePrevious from "@hooks/usePrevious"
import { mutate } from "swr"
import { useProcessPendingAndLastFinishedContext } from "@components/Processes/contexts/ProcessPendingAndLastFinishedContext/hooks/useProcessPendingAndLastFinishedContext"
import { getPrivateApiWordPressCorePath } from "@/WordPressCore/api/routes-private"
import { JSONWordPressCoreApiData } from "@/WordPressCore/types/api-types"
import { WordPressCoreComponents } from "../.."

interface Props {
  data: JSONWordPressCoreApiData[]
  rowsProcessing?: Set<TableRowProcess>
}

const COLUMNS = [
  {
    key: "name",
    name: "Name",
  },
  {
    key: "version",
    name: "Version",
  },
  {
    key: "action",
    name: "",
  },
]

export const AllWordPressCore = ({ data, rowsProcessing }: Props) => {
  const { finished } = useProcessPendingAndLastFinishedContext()

  const previousFinishedTask = usePrevious(finished)

  useEffect(() => {
    if (isNil(previousFinishedTask) && !isNil(finished)) {
      mutate(getPrivateApiWordPressCorePath())
    }
  }, [finished])

  return (
    <System.Table.List<JSONWordPressCoreApiData>
      columns={COLUMNS}
      rows={data}
      withChecked
      columnsClassname="grid-cols-[auto_1fr_1fr_1fr]"
      rowsProcessing={rowsProcessing}
      bulkActions={
        <WordPressCoreComponents.BulkAction.AllWordPressCore items={data} />
      }
    >
      {({ item, columnsClassname }) => {
        return (
          <WordPressCoreRow item={item} columnsClassname={columnsClassname} />
        )
      }}
    </System.Table.List>
  )
}
