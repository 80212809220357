import { ResponseCreateProcessTaskUpdatePlugin } from "@components/Plugin/actions/createProcessTaskUpdatePlugin"
import { useGenericModal } from "@design-system/Context/GenericModal/hooks/useGenericModal"
import { System } from "@design-system/index"
import { WordPressCoreComponents } from "../.."
import { RequestCacheClearFormInputs } from "@components/Processes/components/Form/types"
import { SubmitHandler } from "react-hook-form"
import { WordPressCoreAction } from "@components/WordPressCore/actions"
import { useState } from "react"
import useNotifications from "@hooks/notifications/useNotifications"
import { isNil } from "lodash"

interface Props {
  wpcore: {
    id: string
    version: string
  }
  entities?: {
    [key: string]: any
  }
  projectId: number
  onSuccess?: (props: ResponseCreateProcessTaskUpdatePlugin) => void
  children: React.ReactNode
}

export const WordPressCoreUpdateAction = ({
  wpcore,
  projectId,
  onSuccess,
  entities,
  children,
}: Props) => {
  const { openModal, closeModal } = useGenericModal()
  const [loading, setLoading] = useState(false)
  const { handleNotifySuccess } = useNotifications()

  const onSubmit: SubmitHandler<RequestCacheClearFormInputs> = async (
    values,
  ) => {
    setLoading(true)
    const response = await WordPressCoreAction.updateWordPressCore({
      data: {
        entities: {
          id: wpcore.id,
          version: wpcore.version,
          ...entities,
        },
        projectId: projectId,
      },
      projectsClearCache: values.needCacheClear
        ? [
            {
              projectId: projectId,
            },
          ]
        : [],
    })

    handleNotifySuccess({
      title: "We have started the update",
      message: <>We will inform you when it’s done.</>,
    })

    if (response.code === "success" && !isNil(onSuccess)) {
      onSuccess(response)
    }
    setLoading(false)
    closeModal()
  }

  return (
    <>
      <System.Button.Primary
        onClick={() => {
          openModal({
            component: (
              <WordPressCoreComponents.Modal.UpdateWordPressCore
                onConfirm={onSubmit}
                version={wpcore.version}
                loading={loading}
              />
            ),
          })
        }}
      >
        <System.Svg.Update className="h-4 w-4 opacity-50" />
        {children}
      </System.Button.Primary>
    </>
  )
}
