import { ProcessTasksType } from "@prisma/client"

interface Props {
  state: "failed" | "success" | "progress"
  type: ProcessTasksType
}
export const getTextWordPressCoreProcessing = ({ state, type }: Props) => {
  if (state === "success") {
    switch (type) {
      case ProcessTasksType.UPDATE_CORE:
        return "Updated!"
      case ProcessTasksType.DATABASE_OPTIMIZATION:
        return "Optimized!"
    }
  }

  if (state === "failed") {
    switch (type) {
      case ProcessTasksType.UPDATE_CORE:
        return "Update failed!"
      case ProcessTasksType.DATABASE_OPTIMIZATION:
        return "Optimize failed!"
    }
  }

  switch (type) {
    case ProcessTasksType.UPDATE_CORE:
      return "Updating..."
    case ProcessTasksType.DATABASE_OPTIMIZATION:
      return "Optimizing..."
  }
}
