import { TypeFilteringWordPressCoreItem } from "../constants/TabsFilteringWordPressCore"

export function getFilteringWordPressCore<TData = any>({
  data,
  filters,
}: {
  data: TData[]
  filters: {
    tab: string
    query: string
  }
}): TData[] {
  switch (filters.tab) {
    case TypeFilteringWordPressCoreItem.NEED_UPDATE:
      return data
        .map((item: any) => {
          if (!item.needWordPressCoreUpdate()) {
            return null
          }

          return item
        })
        .filter((plugin) => plugin)
  }

  return data
}
