import { System } from "@design-system/index"
import React from "react"
import { TableColumn, TableRowProcess } from "@design-system/Table/types"

import { useTable } from "@design-system/Table/hooks/useTable"
import { useProcessPendingAndLastFinishedContext } from "@components/Processes/contexts/ProcessPendingAndLastFinishedContext/hooks/useProcessPendingAndLastFinishedContext"
import { JSONWordPressCoreApiData } from "@/WordPressCore/types/api-types"
import { WordPressCoreComponents } from "@components/WordPressCore/components"

interface Props {
  item: JSONWordPressCoreApiData
  columns?: Array<TableColumn>
  columnsClassname?: string
}

export const WordPressCoreRow = ({
  item,
  columns = [],
  columnsClassname,
}: Props) => {
  const { isRowProcessing, getRowProcessing } = useTable()

  const { mutate } = useProcessPendingAndLastFinishedContext()

  const isInProcess = isRowProcessing({
    key: item.id,
  })

  let itemInProcess: TableRowProcess
  if (isInProcess) {
    itemInProcess = getRowProcessing({
      key: item.id,
    })
  }

  return (
    <System.Table.RowWithActions
      columns={columns}
      innerRowClassName={columnsClassname}
      rowKey={item.id}
      item={item}
    >
      <label
        htmlFor={`checkbox_${item.id}`}
        className="flex cursor-pointer items-center gap-4"
      >
        <System.Site.Preview
          site={{
            base_url: item.Project.base_url,
            name: item.Project.name,
          }}
        />
      </label>
      <div className="flex items-center gap-4">
        {item.needWordPressCoreUpdate() ? (
          <System.UpgradeVersion
            leftVersion={item.wordpress_version}
            rightVersion={item.getLatestWordPressVersion()}
            color="blue"
            classNameLeft="min-w-[50px]"
          />
        ) : (
          <System.Text size="large">{item.wordpress_version}</System.Text>
        )}
      </div>
      <div className="flex">
        {itemInProcess ? (
          <>
            <WordPressCoreComponents.TableRowProcessing
              item={item}
              itemInProcess={itemInProcess}
              projectId={item.projectId}
            />
          </>
        ) : (
          <div className="ml-auto">
            {item.needWordPressCoreUpdate() && (
              <WordPressCoreComponents.Action.WordPressCoreUpdate
                wpcore={{
                  id: item.id,
                  version: item.getLatestWordPressVersion(),
                }}
                projectId={item.projectId}
                onSuccess={() => {
                  mutate()
                }}
              >
                Update
                <System.Svg.ChevronsRight className="h-4 w-4 flex-none text-gray-300" />
                {item.getLatestWordPressVersion()}
              </WordPressCoreComponents.Action.WordPressCoreUpdate>
            )}
          </div>
        )}
      </div>
    </System.Table.RowWithActions>
  )
}
