import { WordPressCoreActionComponents } from "./Action"
import { BulkActionWordPressCore } from "./BulkAction"
import { FetchWordPressCoreData } from "./Fetch"
import { WordPressCoreFilters } from "./Filter"
import { WordPressCoreFormComponents } from "./Form"
import { WordPressCoreModal } from "./Modal"
import { WordPressCoreTables } from "./Table"
import { TableRowProcessing } from "./TableRowProcessing"

export const WordPressCoreComponents = {
  Action: WordPressCoreActionComponents,
  BulkAction: BulkActionWordPressCore,
  Modal: WordPressCoreModal,
  Fetch: FetchWordPressCoreData,
  Filter: WordPressCoreFilters,
  Form: WordPressCoreFormComponents,
  Table: WordPressCoreTables,
  TableRowProcessing: TableRowProcessing,
}
