import { UpdateCoreEntity } from "@/Manager/Processes/ProcessCreateManager"
import { DataClearCache } from "@/Repositories/Process/createProcessWithTasks"
import { TableRowProcess } from "@design-system/Table/types"
import { ProcessTasksType } from "@prisma/client"
import { createBulkProcessTask } from "./createBulkProcessTask"

export interface ResponseCreateProcessTaskUpdateWordPressCore {
  code: string
  data: {
    id: string
    ProcessTask: Array<{
      projectId: number
      retry: number
      type: ProcessTasksType
      entities?: any
      state?: any
    }>
  }
  rowProcessings: Set<TableRowProcess>
}

export const createProcessTaskUpdateWordPressCore = async ({
  data,
  projectsClearCache,
}: {
  data: {
    entities: UpdateCoreEntity
    projectId: number
  }
  projectsClearCache: Array<DataClearCache>
}): Promise<ResponseCreateProcessTaskUpdateWordPressCore> => {
  return await createBulkProcessTask({
    data: [
      {
        ...data,
        type: ProcessTasksType.UPDATE_CORE,
      },
    ],
    projectsClearCache,
  })
}
