import { System } from "@design-system/index"
import React from "react"
import { useGenericModal } from "@design-system/Context/GenericModal/hooks/useGenericModal"
import { ProcessComponents } from "@components/Processes"
import { RequestCacheClearFormInputs } from "@components/Processes/components/Form/types"

interface Props {
  onConfirm: (props: RequestCacheClearFormInputs) => void
  version: string
  loading?: boolean
}

export const ModalUpdateWordPressCore = ({
  onConfirm,
  version,
  loading,
}: Props) => {
  const { closeModal } = useGenericModal()

  return (
    <>
      <div className="text-center">
        <System.Title className="mb-2">Are you sure?</System.Title>
        <System.Text>
          Please confirm the update of the WordPress you have selected
        </System.Text>
      </div>
      <ProcessComponents.Form.ResquestCacheClear.Provider>
        <ProcessComponents.Form.ResquestCacheClear.Form onSubmit={onConfirm} />
        <div className="mt-8 flex items-center gap-2">
          <System.Button.Outlined
            onClick={closeModal}
            className="w-full justify-center"
          >
            Cancel
          </System.Button.Outlined>
          <System.Form.Submit
            form="request-cache-clear"
            className="w-full justify-center"
            loading={loading}
          >
            Update WP Core
            <System.Svg.ChevronsRight className="h-4 w-4 opacity-50" />{" "}
            {version}
          </System.Form.Submit>
        </div>
      </ProcessComponents.Form.ResquestCacheClear.Provider>
    </>
  )
}
