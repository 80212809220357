import React from "react"
import { ProcessTasksType } from "@prisma/client"
import { System } from "@design-system/index"
import { IBulkAction } from "@design-system/Table/components/BulkActions/List"
import { useProcessPendingAndLastFinishedContext } from "@components/Processes/contexts/ProcessPendingAndLastFinishedContext/hooks/useProcessPendingAndLastFinishedContext"
import { useGenericModal } from "@design-system/Context/GenericModal/hooks/useGenericModal"
import { JSONWordPressCoreApiData } from "@/WordPressCore/types/api-types"
import { WordPressCoreComponents } from "@components/WordPressCore/components"

interface Props {
  items?: JSONWordPressCoreApiData[]
  projectId?: number
  checkedRows: {
    checkAllRows: () => void
    checkedRows: Set<number | string>
    clearCheckedRows: () => void
    rowKeys: Set<number | string>
  }
  onSubmit: () => Promise<void>
}

export function BulkActionChooseDatabaseOptimization({
  checkedRows,
  onSubmit,
}: Props) {
  const { openModal, closeModal } = useGenericModal()

  const { mutate } = useProcessPendingAndLastFinishedContext()

  if (checkedRows.checkedRows.size === 0) {
    return
  }

  const handleConfirm = async () => {
    try {
      await onSubmit()
      mutate()
    } catch (error) {}
    closeModal()
  }

  const ACTIONS = [
    {
      key: ProcessTasksType.DATABASE_OPTIMIZATION,
      label: (
        <>
          <System.Svg.Database className="h-4 w-4" />
          Optimize
        </>
      ),
      onClick: () => {
        openModal({
          component: (
            <WordPressCoreComponents.Modal.ConfirmChooseDatabaseOptimization
              onConfirm={handleConfirm}
            />
          ),
        })
      },
    },
  ] as IBulkAction[]

  return (
    <>
      <System.Content.BulkActions.List
        actions={ACTIONS}
        isShowing={checkedRows.checkedRows.size > 0}
        checkedRows={checkedRows}
      />
    </>
  )
}
