import useSWR from "swr"
import { getPrivateApiWordPressCorePath } from "@/WordPressCore/api/routes-private"
import fetcherPrivateApiUmbrella from "@helpers/fetcherPrivateApiUmbrella"
import { isEmpty, isNil } from "lodash"

import { morphism } from "morphism"

import { JSONWordPressCoreApiData } from "@/WordPressCore/types/api-types"
import WordPressCoreModel, {
  SchemaJSONWordPressCoreApiDataToWordPressCoreModel,
} from "@/WordPressCore/models/WordPressCoreModel"

interface Props {
  suspense?: boolean
  options?: {
    refreshInterval?: number
  }
}

export const useWordPressCore = (
  { suspense, options }: Props = {
    suspense: true,
  },
) => {
  const { data: response, ...rest } = useSWR<{
    items: {
      data: JSONWordPressCoreApiData[]
      code: string
    }
    total: number
  }>(getPrivateApiWordPressCorePath(), {
    suspense: suspense,
    fetcher: fetcherPrivateApiUmbrella(),
    ...options,
  })

  const {
    items: { code, data },
  } = response || {}

  if (isNil(data) || isEmpty(data) || code !== "success") {
    return {
      data: [] as WordPressCoreModel[],
      ...rest,
    }
  }

  return {
    data: morphism(
      SchemaJSONWordPressCoreApiDataToWordPressCoreModel,
      data,
      WordPressCoreModel,
    ),
    ...rest,
  }
}
