import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from "react-hook-form"
import { ChooseDatabaseOptimizationFormInputs } from "../types"

import { System } from "@design-system/index"
import { WordPressCoreComponents } from "../.."
import { useEffect } from "react"
import { useApplication } from "@design-system/Context/GenericApplication/hooks/useApplication"

export const ChooseDatabaseOptimizationFormProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const methods = useForm<ChooseDatabaseOptimizationFormInputs>()

  return <FormProvider {...methods}>{children}</FormProvider>
}

interface Props {
  id?: string
  databaseOptimization: {
    revision: number
    autoDraft: number
    trashPost: number
    spamComment: number
    trashComment: number
    expiredTransient: number
    table: number
  }
  onSubmit: SubmitHandler<ChooseDatabaseOptimizationFormInputs>
}

type TableItem = {
  id: string
  title: string
  description: string
  total?: number
}

const Row = ({ item }: { item: TableItem }) => {
  if (item.total === 0) {
    return (
      <System.Table.InnerRow
        item={item}
        isChecked={false}
        rowKey={item.id}
        className="bg-gray-50"
      >
        <div className="ml-12">
          <System.Text size="large">
            <System.Strong>{item.title}</System.Strong>
          </System.Text>
          <System.Text>
            You don't have any {item.title.toLowerCase()} in your database.
          </System.Text>
        </div>
      </System.Table.InnerRow>
    )
  }
  return (
    <System.Table.InnerRowWithActions rowKey={item.id} item={item} columns={[]}>
      <label htmlFor={`checkbox_${item.id}`} className="cursor-pointer">
        <System.Text size="large">
          <System.Strong>{item.title}</System.Strong>{" "}
        </System.Text>
        <System.Text>{item.description}</System.Text>
      </label>
    </System.Table.InnerRowWithActions>
  )
}

export const ChooseDatabaseOptimizationForm = ({
  id = "choose-database-optimization",
  databaseOptimization,
  onSubmit,
}: Props) => {
  const { handleSubmit, setValue, getValues, watch } =
    useFormContext<ChooseDatabaseOptimizationFormInputs>()

  const checkAllRows = () => {
    for (const [key, value] of Object.entries(databaseOptimization)) {
      if (value > 0) {
        let value:
          | "checkbox_table"
          | "checkbox_revision"
          | "checkbox_autoDraft"
          | "checkbox_trashPost"
          | "checkbox_spamComment"
          | "checkbox_trashComment"
          | "checkbox_expiredTransient"

        switch (key) {
          case "revision":
            value = "checkbox_revision"
            break
          case "autoDraft":
            value = "checkbox_autoDraft"
            break
          case "trashPost":
            value = "checkbox_trashPost"
            break
          case "spamComment":
            value = "checkbox_spamComment"
            break
          case "trashComment":
            value = "checkbox_trashComment"
            break
          case "expiredTransient":
            value = "checkbox_expiredTransient"
            break
          case "table":
          default:
            value = "checkbox_table"
            break
        }

        setValue(value, true)
      }
    }
  }

  const clearCheckedRows = () => {
    setValue("checkbox_table", false)
    setValue("checkbox_revision", false)
    setValue("checkbox_autoDraft", false)
    setValue("checkbox_trashPost", false)
    setValue("checkbox_spamComment", false)
    setValue("checkbox_trashComment", false)
    setValue("checkbox_expiredTransient", false)
  }

  // Necessary to watch the values of the form and re-render the component
  const values = watch()

  const checkedRows = new Set(
    Object.entries(getValues())
      .filter((value) => {
        return value[1] === true ? value[0] : null
      })
      .map((value) => {
        return value[0]
      }),
  )

  const { setContainerClassName } = useApplication()

  useEffect(() => {
    if (checkedRows.size > 0) {
      setContainerClassName("pb-24")
    } else {
      setContainerClassName("")
    }
  }, [checkedRows.size])

  return (
    <>
      <form id={id} onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <div>
          <div className="mb-4 flex items-center gap-4">
            <System.IconBullet icon="Plugin" size="md" />
            <div className="flex flex-col">
              <System.Title>Post Cleanup</System.Title>
              <System.Text>
                Post revisions and drafts will be permanently deleted. Do not
                use this option if you need to retain revisions or drafts.
              </System.Text>
            </div>
          </div>

          <System.Table.List<TableItem>
            columns={[]}
            rows={[
              {
                id: "revision",
                title: "Revisions",
                description: `${databaseOptimization.revision} revisions in your database`,
                total: databaseOptimization.revision,
              },
              {
                id: "autoDraft",
                title: "Auto Drafts",
                description: `${databaseOptimization.autoDraft} draft in your database`,
                total: databaseOptimization.autoDraft,
              },
              {
                id: "trashPost",
                title: "Trashed Posts",
                description: `${databaseOptimization.trashPost} trashed posts in your database`,
                total: databaseOptimization.trashPost,
              },
            ]}
            withChecked
            withFormContext
            isStacked
          >
            {({ item }) => {
              return (
                <>
                  <Row item={item} />
                </>
              )
            }}
          </System.Table.List>
        </div>

        <div>
          <div className="mb-4 flex items-center gap-4">
            <System.IconBullet icon="Message" size="md" />
            <div className="flex flex-col">
              <System.Title>Comments Cleanup</System.Title>
              <System.Text>
                Spam and trashed comments will be permanently deleted.
              </System.Text>
            </div>
          </div>

          <System.Table.List<TableItem>
            columns={[]}
            rows={[
              {
                id: "spamComment",
                title: "Spam Comments",
                description: `${databaseOptimization.spamComment} spam comments in your database`,
                total: databaseOptimization.spamComment,
              },
              {
                id: "trashComment",
                title: "Trashed Comments",
                description: `${databaseOptimization.trashComment} trashed comments in your database`,
                total: databaseOptimization.trashComment,
              },
            ]}
            withChecked
            withFormContext
            isStacked
          >
            {({ item }) => {
              return (
                <>
                  <Row item={item} />
                </>
              )
            }}
          </System.Table.List>
        </div>

        <div>
          <div className="mb-4 flex items-center gap-4">
            <System.IconBullet icon="Tools" size="md" />
            <div className="flex flex-col">
              <System.Title>Transients Cleanup</System.Title>
              <System.Text>
                Transients are temporary options; they are safe to remove. They
                will be automatically regenerated as your plugins require them.
              </System.Text>
            </div>
          </div>

          <System.Table.List<TableItem>
            columns={[]}
            rows={[
              {
                id: "expiredTransient",
                title: "Transients",
                description: `${databaseOptimization.expiredTransient} expired transients in your database`,
                total: databaseOptimization.expiredTransient,
              },
            ]}
            withChecked
            withFormContext
            isStacked
          >
            {({ item }) => {
              return (
                <>
                  <Row item={item} />
                </>
              )
            }}
          </System.Table.List>
        </div>

        <div>
          <div className="mb-4 flex items-center gap-4">
            <System.IconBullet icon="Database" size="md" />
            <div className="flex flex-col">
              <System.Title>Database Cleanup</System.Title>
              <System.Text>Reduces overhead of database tables</System.Text>
            </div>
          </div>

          <System.Table.List<TableItem>
            columns={[]}
            rows={[
              {
                id: "table",
                title: "Optimize Tables",
                description: `${databaseOptimization.table} tables in your database`,
                total: databaseOptimization.table,
              },
            ]}
            withChecked
            withFormContext
            isStacked
          >
            {({ item }) => {
              return (
                <>
                  <Row item={item} />
                </>
              )
            }}
          </System.Table.List>
        </div>
        <WordPressCoreComponents.BulkAction.ChooseDatabaseOptimization
          checkedRows={{
            checkAllRows: checkAllRows,
            clearCheckedRows: clearCheckedRows,
            checkedRows: checkedRows,
            rowKeys: new Set(Object.keys(getValues())),
          }}
          onSubmit={handleSubmit(onSubmit)}
        />
      </form>
    </>
  )
}

export default {
  Provider: ChooseDatabaseOptimizationFormProvider,
  Form: ChooseDatabaseOptimizationForm,
}
