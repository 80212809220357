import { ResponseCreateProcessTaskUpdatePlugin } from "@components/Plugin/actions/createProcessTaskUpdatePlugin"
import { System } from "@design-system/index"
import { useGenericStep } from "@design-system/Context/GenericStep/hooks/useGenericStep"
import {
  KEY_STEP_DATABASE_OPTIMIZATION,
  STEP_CHOOSE_DATABASE_OPTIMIZATION,
} from "@components/WordPressCore/constants/StepDatabaseOptimization"

interface Props {
  databaseOptimization: {
    revision: number
    autoDraft: number
    trashPost: number
    spamComment: number
    trashComment: number
    expiredTransient: number
    table: number
  }
  entities?: {
    [key: string]: any
  }
  projectId: number
  onSuccess?: (props: ResponseCreateProcessTaskUpdatePlugin) => void
}

export const DatabaseOptimizationAction = ({
  projectId,
  databaseOptimization,
}: Props) => {
  const { setCurrentStep } = useGenericStep()

  return (
    <>
      <System.Button.Outlined
        onClick={() => {
          setCurrentStep({
            key: KEY_STEP_DATABASE_OPTIMIZATION,
            current: STEP_CHOOSE_DATABASE_OPTIMIZATION,
            data: {
              projectIds: [projectId],
              databaseOptimization: databaseOptimization,
            },
          })
        }}
      >
        <System.Svg.Database className="h-4 w-4" />
        Optimize
      </System.Button.Outlined>
    </>
  )
}
