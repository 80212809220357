import {
  JSONWordPressLatestData,
  JSONWordPressVersionInfo,
} from "../types/api-types"

class WordPressVersionInfo implements JSONWordPressVersionInfo {
  latest?: JSONWordPressLatestData
  is_up_to_date: boolean
  constructor({
    latest,
    is_up_to_date,
  }: {
    latest?: JSONWordPressLatestData
    is_up_to_date: boolean
  }) {
    this.latest = latest
    this.is_up_to_date = is_up_to_date
  }

  needWordPressCoreUpdate(): boolean {
    return !this.is_up_to_date
  }

  getSqlVersionRecommandedByWordPress(): string {
    try {
      return this.latest?.mysql_version
    } catch (e) {
      return ""
    }
  }

  getPhpVersionRecommandedByWordPress(): string {
    try {
      return this.latest?.php_version
    } catch (e) {
      return ""
    }
  }

  getLatestWordPressVersion(): string {
    try {
      return this.latest?.version
    } catch (e) {
      return ""
    }
  }
}

export default WordPressVersionInfo
