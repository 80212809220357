import FetchApiProcess from "@/FetchApi/Process"
import { PropsCreateProcessTasksApi } from "@/FetchApi/Process/createProcess"
import {
  DatabaseOptimizationEntity,
  UpdateCoreEntity,
} from "@/Manager/Processes/ProcessCreateManager"
import { DataClearCache } from "@/Repositories/Process/createProcessWithTasks"
import { TableRowProcess } from "@design-system/Table/types"
import { ProcessTasksType } from "@prisma/client"
import { get, isNil } from "lodash"

interface Response {
  code: string
  data: {
    id: string
    ProcessTask: Array<{
      projectId: number
      retry: number
      type: ProcessTasksType
      entities?: any
      state?: any
    }>
  }
  rowProcessings: Set<TableRowProcess>
}

interface Props {
  data: Array<
    PropsCreateProcessTasksApi & {
      entities: UpdateCoreEntity | DatabaseOptimizationEntity
    }
  >
  projectsClearCache: Array<DataClearCache>
}

export const createBulkProcessTask = async ({
  data,
  projectsClearCache,
}: Props): Promise<Response> => {
  const response = await FetchApiProcess.createProcess({
    tasks: data,
    data: {
      clear_cache: projectsClearCache,
    },
  })

  let code = null
  if (!isNil(response)) {
    code = response.code
  }

  const lines = new Set<TableRowProcess>()
  if (code === "success") {
    data.forEach((item) => {
      lines.add({
        key: item.projectId,
        type: item.type,
        task: {
          finished_at: null,
          state: "waiting",
          code: null,
          entities: item.entities,
        },
      })
    })
  }

  return {
    code: response.code,
    data: get(response, "data", null),
    rowProcessings: lines,
  }
}
