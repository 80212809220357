import { DatabaseOptimizationEntity } from "@/Manager/Processes/ProcessCreateManager"

import { TableRowProcess } from "@design-system/Table/types"
import { ProcessTasksType } from "@prisma/client"
import { createBulkProcessTask } from "./createBulkProcessTask"

export interface ResponseCreateProcessTaskDatabaseOptimization {
  code: string
  data: {
    id: string
    ProcessTask: Array<{
      projectId: number
      retry: number
      type: ProcessTasksType
      entities?: any
      state?: any
    }>
  }
  rowProcessings: Set<TableRowProcess>
}

export const createProcessTaskDatabaseOptimization = async ({
  data,
}: {
  data: {
    entities: DatabaseOptimizationEntity
    projectId: number
  }[]
}): Promise<ResponseCreateProcessTaskDatabaseOptimization> => {
  return await createBulkProcessTask({
    data: data.map((item) => {
      return {
        ...item,
        type: ProcessTasksType.DATABASE_OPTIMIZATION,
      }
    }),
    projectsClearCache: [],
  })
}
