import { System } from "@design-system/index"
import { useGenericModal } from "@design-system/Context/GenericModal/hooks/useGenericModal"
import { useState } from "react"

interface Props {
  onConfirm: () => void
}

export function ModalConfirmChooseDatabaseOptimization({ onConfirm }: Props) {
  const { closeModal } = useGenericModal()
  const [loading, setLoading] = useState(false)

  return (
    <>
      <div className="text-center">
        <System.Title className="mb-2">Are you sure?</System.Title>
        <System.Text>
          Please confirm the action to optimize the database.
        </System.Text>
      </div>

      <div className="mt-8 flex items-center gap-2">
        <System.Button.Outlined
          onClick={closeModal}
          className="w-full justify-center"
        >
          Cancel
        </System.Button.Outlined>
        <System.Button.Primary
          className="w-full justify-center"
          onClick={() => {
            setLoading(true)
            onConfirm()
          }}
          loading={loading}
        >
          Confirm Database Optimization
        </System.Button.Primary>
      </div>
    </>
  )
}
