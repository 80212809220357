export enum TypeFilteringWordPressCoreItem {
  ALL = "ALL",
  NEED_UPDATE = "NEED_UPDATE",
}

export const TABS_FILTERING_WORDPRESS_CORE = [
  {
    value: TypeFilteringWordPressCoreItem.ALL,
    label: "All",
  },
  {
    value: TypeFilteringWordPressCoreItem.NEED_UPDATE,
    label: "Update available",
  },
]
