import React from "react"
import { useGenericFilteringItemsWithTabs } from "@design-system/Context/GenericFilteringItemsWithTabs/hooks/useGenericFilteringItemsWithTabs"
import { System } from "@design-system/index"
import { TABS_FILTERING_WORDPRESS_CORE } from "@components/WordPressCore/constants/TabsFilteringWordPressCore"
import { getFilteringWordPressCore } from "@components/WordPressCore/helpers/getFilteringWordPressCore"

export function TabsFilteringWordPressCore() {
  const { state, handleChangeTab } = useGenericFilteringItemsWithTabs()

  return (
    <>
      <div className="flex items-center">
        <System.Tab.Navigation>
          {TABS_FILTERING_WORDPRESS_CORE.map((tab) => {
            const data = getFilteringWordPressCore({
              data: state.data,
              filters: {
                query: state.query,
                tab: tab.value,
              },
            })

            return (
              <System.Tab.Button
                count={data.length}
                key={tab.value}
                className="text-gray-500 hover:text-gray-700"
                isCurrent={state.tab === tab.value}
                onClick={() => {
                  handleChangeTab({
                    value: tab.value,
                  })
                }}
              >
                {tab.label}
              </System.Tab.Button>
            )
          })}
        </System.Tab.Navigation>
      </div>
    </>
  )
}
