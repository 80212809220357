import { ReactNode } from "react"
import { KeyedMutator } from "swr"
import { useWordPressCore } from "@components/WordPressCore/hooks/useWordPressCore"
import { JSONWordPressCoreApiData } from "@/WordPressCore/types/api-types"
import WordPressCoreModel from "@/WordPressCore/models/WordPressCoreModel"

interface PropsChildren {
  error: any
  mutate: KeyedMutator<{
    items: {
      data: JSONWordPressCoreApiData[]
      code: string
    }
    total: number
  }>
  isValidating: boolean
  isLoading: boolean
  data: WordPressCoreModel[]
}

interface Props {
  children: (props: PropsChildren) => ReactNode
}

export function FetchAllWordPressCoreData({ children }: Props) {
  const response = useWordPressCore()

  return (
    <>
      {children({
        ...response,
      })}
    </>
  )
}
